
body {
    user-select: none !important;
  }
* {
    font-family: Inter;
}   
.footerTwo p{

text-align: center;

}
.navbarss .row .col-sm-3{
    background:#292D76;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 30px;
}
.navbarss .row .col-sm-3 a {
    text-transform: capitalize;
    background-color: #fff;
    padding: 10px 70px;
    border-radius: 0;
    border: 1px solid #fff;
    font-weight: 700;
    color: #000;
    box-shadow: none;
    height: auto;
    text-decoration: none;
}
.fade.buyNowModal.modal.show .modal-dialog {
    max-width: 70%;
}
.ant-select-dropdown {
    z-index: 9999 ;
}
.fade.buyNowModal.modal.show .col-sm-8.forrmm{
    width: 70% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.ant-tabs-left >.ant-tabs-nav .ant-tabs-tab, .ant-tabs-right >.ant-tabs-nav .ant-tabs-tab, .ant-tabs-left >div>.ant-tabs-nav .ant-tabs-tab, .ant-tabs-right >div>.ant-tabs-nav .ant-tabs-tab {
    padding: 0px 24px;
    text-align: center;
}
.modal-body .row.ceenterr{
    margin: 40px 0 !important;
    align-items: flex-start !important;
}
.modal-header h2 {
    font-size: 24px;
    margin-bottom: 0 !important;
    font-weight: 700;
}
.modal-body .Pricee {
    padding: 10px 0;
}
.modal-body button{
    border-radius: 10px;
}
.ant-tabs >.ant-tabs-nav .ant-tabs-nav-list, .ant-tabs >div>.ant-tabs-nav .ant-tabs-nav-list {
    padding: 40px 0 20px !important;
}
.row.boddyTab .col-sm-12 .ant-tabs >.ant-tabs-nav .ant-tabs-nav-list, .row.boddyTab .col-sm-12 .ant-tabs >div>.ant-tabs-nav .ant-tabs-nav-list {
    padding: 0 !important;
}
.fade.buyNowModal.modal.show .col-sm-2.forrm{
    width: 30% !important;
}
.navbarss .row .col-sm-3 a:hover{
    background-color: #00A6A7 !important;
    border: 1px solid #292D76 !important;
    color: #fff !important;
    transition: all ease-in-out 400ms;
}
.bodySVgsssss {
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.row.home .col-sm-12.imageess{
    justify-content: space-evenly;
    display: flex;
    padding-bottom: 30px;
}
.row.home .col-sm-12.imageess img {
    cursor: pointer;
    border-radius: 15px;
    padding: 15px;
    height: 400px;
    width: 400px;
    object-fit: contain;
    background-color: #fff;
    transition: all 300ms ease-in-out;
}
.row.home .col-sm-12.imageess img:hover {
    box-shadow: 0px 4px 15px 0px #00000038 !important;
    transition: all 300ms ease-in-out;
}
.row.home .active {
    box-shadow: 0px 4px 15px 0px #00000038 !important;
    transition: all 300ms ease-in-out;
}
body{
    background: #F8F8F8;
}
.row.home button {
    padding: 12px 120px;
    height: auto;
    border-radius: 5px;
    margin: 50px 0;
    background: linear-gradient(180deg, #078BE6 0%, #19469D 100%) !important;
    color: #fff !important;
    font-weight: 500;
    font-size: 20px;
}
.Pricee{
    background: #EFEFEF;
    border-radius: 10px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    margin-top: 10px;
}
button.buynow, button.buynow:hover{
    margin: 20px 0;
    background: linear-gradient(180deg, #078BE6 0%, #19469D 100%) !important;
    font-size: 20px;
    font-weight: 600;
    height: 55px;
    border-radius: 10px;
    width: 100%;
    color: #fff !important;
}
.button{
    width: 100%;
    display: flex;
    justify-content: center;
}
.Pricee .big{
    font-size: 24px;
    font-weight: 600;
}
.Pricee .small{
    font-size: 10px;
}
.Pricee p{
    color: #078BE6;
    margin-bottom: 0px;
}
.col-sm-12.ccentre{
    display: flex;
    justify-content: center;
}
.bodySVgsssss svg{
    height: 100%;
    width: 100%;
}
.col-sm-10.buttonDispaly .ceeenter button img{
    margin-bottom: 0 !important;
}
.col-sm-10.buttonDispaly {
    padding-left: 0 !important;
}
.ant-tabs-tab.ant-tabs-tab-active {
    margin: 0;
}
.ant-tabs-tab {
    margin: 0;
   
}
.ant-tabs-nav-list{
    gap: 18px;
}
.ant-tabs-nav-list div {
    width: 95%;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;
}
.row.home h2 {
    text-align: center;
    padding: 70px 0;
    font-weight: 600;
    font-size: 50px;
}
.ant-tabs-nav {
    width: 300px;

}
.mycont {
    max-width: 100% !important;
    padding: 0 !important;
}
.navbarss .row.second .col-sm-10{
    display: flex;
    justify-content: center;
    align-items: center;
}
.row.second{
    margin-bottom: 20px;
}
.dropbtn {
    background-color: #292D76;
    color: white;
}
.dropdown {
    position: relative;
    display: flex;
    align-items: end;
}
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    min-width: 300px;
    text-decoration: none;
    display: block;
}
.dropdown-content a:hover{
    background-color: #00A6A7;
    transition: all ease-in-out 300ms;
}
.col-sm-3.newsLetter p.headFooter{
    font-family: "monospace"!important;
    color: #00A6A7 !important;
    font-weight: 800;
    font-size: 18px;
}
.footerTwo .row .col-sm-12{
    padding: 20px 50px;
   
}
.footerTwo .row .col-sm-12 ul{
    display: flex;
    margin-bottom: 0;
    list-style-type: none;
}
.footerTwo .row .col-sm-12 ul li{
    padding: 0 20px;
    cursor: pointer;
}
.footerTwo .row .col-sm-12 ul li a{
    color: #fff !important;
    font-size: 12px;
    text-decoration: none;
}
.footerTwo .row .col-sm-12 p{
    margin-bottom: 0;
    font-size: 18px;
    opacity: 0.7;
    color: white;
    align-self: center;
}
.container-fluid.footer{
    display: flex;
    background: url('./assets/images/FooterBackground.png');
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-size: 100% 100%;
}
.container-fluid.footerTwo{
    background: #216FB7;
    width: 100%;
}
p.footerText {
    color: #000;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    padding: 1.5rem 0;
}
.col-sm-3.lisst{
    padding-left: 80px !important;
}
.row.footerOne {
    padding: 20px;
    max-width: 100%;
}
.container {
    max-width: 90%;
}
.row.boddyTab .col-sm-12 .ant-tabs-nav-wrap {
    width: 99%;
}
.row.boddyTab .col-sm-12 .ant-tabs-tab {
    margin: 0;
    width: 60%;
}
.row.boddyTab .col-sm-12 .ant-tabs-nav-list {
    width: 70%;
    text-align: center;
}
.row.boddyTab .col-sm-12 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none;
}
.ant-tabs .ant-tabs-ink-bar {
  width: 8px ! IMPORTANT;
    height: 150px ! IMPORTANT;
    background: linear-gradient(180deg, #078BE6 0%, #19469D 100%);

}
.row.row.footerOne .col-sm-3{
    padding: 0 24px ;
}
.row.footerOne .col-sm-3 ul li{
    list-style-type: none;
    margin-bottom: 10px;
}
.row.footerOne input{
    height: 50px;
    border-radius: 0;
    border: unset;
    background: #d7d9de !important;
}
.row.footerOne .col-sm-3 button, .row.footerOne .col-sm-3 button:hover{
    border: none;
    border-radius: 0;
    height: 50px;
    width: 100%;
    font-family: Inter;
    background: #292D76;
    color: #fff;
}
.socialImages img{
    opacity: .5;
}

.row.footerOne .col-sm-3 ul{
    padding-left: 0;
}
.row.footerOne .col-sm-3 ul li a{
    text-decoration: none;
    color: #3b3b3b;
    font-size: 14px;
    font-weight: 600;
}
.row.footerOne .col-sm-3 ul li p{
    text-decoration: none;
    color: #3b3b3b;
    font-size: 14px;
    margin-bottom: 0;
}
.row.footerOne .col-sm-3.contact ul li{
    padding-bottom: 12px;
}
p.headFooter{
    color: #2086BA;
    font-size: 18px;
    margin-top: 40px;
    font-weight: 800;
}
.dropdown:hover .dropdown-content {
    display: block;
}  
.dropdown-content {
    margin-left: -80px;
    padding: 10px 5px;
    display: none;
    position: absolute;
    background-color: #292D76;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.5);
    z-index: 999;
    top: 24px;
}
.navbarss .row.second .col-sm-10 ul{
    display: flex;
    list-style-type: none;
    margin-bottom: 0;
}
.navbarss .row.second .col-sm-10 ul a{
    color: white;
    font-weight: 500;
    text-decoration: none;
}
.navbarss .row.second .col-sm-10 ul p{
    color: white;
    font-weight: 500;
    margin-bottom: 0;
    text-decoration: none;
}
.downLOad{
    background: #292D76 !important;
    border: 1px solid #292D76 !important;
    color: white !important;
    width: 100%;
    border-radius: 0;
    height: 45px;
    margin-top: 10px;
}
.navbarss .row.second .col-sm-2{
    padding: 0 40px;
}
.navbarss .row.second .col-sm-2 img {
    width: 75%;
}
.swiper-button-prev, .swiper-button-next {
    color: #fff !important;
    font-size: 16px ! IMPORTANT;
}
.swiper-button-prev:after, .swiper-button-next:after {
    line-height: 1;
    color: #fff !important;
    font-size: 20px !important;
    background-color: #174CA3;
    padding: 10px;
    border-radius: 10px;
}
.navbarss .row.second .col-sm-10 ul li{
    padding: 0 40px;
    cursor: pointer;
}
.navbarss .row.second .col-sm-9{
    padding: 20px 0;
}
.navbarss .row.second{
    background: #292D76;
}
.col-sm-12.previeeww {
    background: #F8F8F8;
    /* border: 1px solid #CFCFCF; */
    padding: 60px 12px 60px 0;
    box-shadow: none !important;
    border-radius: 10px;
}
.col-sm-12.previeeww {
    padding-right: 0 !important;
}
.ant-tabs-nav {
    background: #fff;
    box-shadow: 0px 4px 50px 0px #0000001A;
    border-radius: 15px 0px 0px 15px !important;
}
.row.boddyTab .ant-tabs-nav {
    background: #fff;
    box-shadow: none !important;
}
.col-sm-10.buttonDispaly button:hover, .ant-tabs-tab:hover button{
    box-shadow: 0px 17px 25px 0px #0E6FC838;
    color: black !important;
}
.col-sm-10.buttonDispaly button {
    width: 100%;
    height: 200px;
    margin: 0px 0 0;
    box-shadow: 0px 3.57px 22.29px 0px #0000001A;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
}
.ant-skeleton-image.imageHeight {
    width: 400px !important;
    height: 400px !important;
}
.col-sm-12.formText {
    width: 95%;
}
.lottie-dash {
    width: 100%;
}
.lottie-dash div{

    height: 300px !important;
    }

.fleexWrapColor{
    display: flex;
    /* gap: 5px; */
    flex-wrap: wrap;
    padding-top: 25px;
}
.row.boddyTab .ant-tabs-tab.ant-tabs-tab-active{
    background: linear-gradient(180deg, #078BE6 0%, #19469D 100%) !important;
    color: #fff !important;
}
.row.boddyTab .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #fff !important;
}
.fleexWrapColorInside {
    display: flex;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}
.fleexWrapColorOutsiude {
    display: flex;
    border-radius: 50%;
    align-items: center;
    cursor: pointer;
    width: 70px;
    height: 70px;
    justify-content: center;
    border: 1px solid #929292 !important;
}
.ceeenter{
    display: flex;
    justify-content: center;
    width: 100%;
}
.restart{
    background: #EFEFEF !important;
    width: auto !important;
    box-shadow: none !important;
    height: auto !important;
    padding: 10px 30px;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
}
.restart img{
    width: 16px !important;
    height: 16px !important;
    margin-right: 10px;
}
.row.boddyTab .ant-tabs-nav, .row.boddyTab .ant-tabs-nav-wrap{
    width: 100%;
}
.bodySVgsssss{
    width: 100%;
    display: flex;
    justify-content: center;
}
.row.ceenterr{
    align-items: flex-start;
    margin: 110px 0;
}
 /* .col-sm-2.forrm {
} */
.bodySVgsssss img{
    width: 80%;
    cursor: pointer;
}
.row.contact{
    border: 1.11px solid #BCBCBC;
    margin: 0 0 30px;
    padding: 0 20px 30px;
    border-radius: 10px;
    width: 100%;
}
.container-fluid.checkout .row.payment.contact form {
    width: 100%;
}
.row.payment.contact {
    width: 80%;
}
.container-fluid.checkout{
    /* margin: 100px 0; */
    display: flex;
    justify-content: center;
}
.row.contact h2{
    font-weight: 700;
    font-size: 22px;
    margin-top: 50px;
    margin-bottom: 20px;
}
.swiper-wrapper .swiper-slide {
    /* width: 33% !important; */
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.swiper-wrapper .swiper-slide svg{
    height: 400px ;
}
.checkout .ant-col.ant-form-item-label {
    margin-bottom: 0 !important;
    padding-bottom: 4px !important;
}
.checkout .row.contact input{
    height: 45px;
    border: 1.11px solid #CBCBCB;
    border-radius: 8px;
}
label.ant-radio-wrapper.ant-radio-wrapper-checked.ant-radio-wrapper-in-form-item{
    border: 1.11px solid #141718;
    width: 100% !important;
    padding: 10px 20px;
}
.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-navigation {
    width: 80%;
}
label.ant-radio-wrapper.ant-radio-wrapper-in-form-item {
    border: 1.11px solid #6C7275;
    width: 100% !important;
    border-radius: 8px;
    padding: 10px 20px;
}
label.ant-radio-wrapper.ant-radio-wrapper-in-form-item img{
    width: 27px;
    height: 27px;
}
.row.contact .ant-input::placeholder, .row.contact span.ant-select-selection-placeholder{
    color: #9D9D9D;
    font-weight: 400;
}
.row.contact .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #000;
    background-color: #000;
}
.container-fluid.checkout form{
    width: 85%;
}
.fulll{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 40px;
}
.row.payment .ant-form-item .ant-form-item-label >label {
    font-weight: 700;
    font-size: 13.5px;
    color: #6C7275 !important;
}
.ant-radio-group.ant-radio-group-outline, .ant-space-item, .ant-space.ant-space-vertical.ant-space-gap-row-small.ant-space-gap-col-small{
    width: 100% !important;
}
.row.contact .ant-select.ant-select-outlined.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
    height: 45px;
}
.row.contact .ant-select-selector {
    height: 44px;
    border-radius: 8px;
    border: 1.11px solid #CBCBCB;
}
.checkout .row.personal .ant-col.ant-form-item-label {
    font-weight: 700;
    font-size: 13.5px;
}
.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
}
.row.thankyoouu{
    text-align: center;
    padding: 3% 10%;
}
.checkout button{
    background: linear-gradient(180deg, #078BE6 0%, #19469D 100%) !important;
    width: 100%;
    color: #fff !important;
    height: 55px;
}
.row.thankyoouu h2{
    font-weight: 700;
    font-size: 62px;
    margin-bottom: 30px;
}
.row.thankyoouu p{
    color: #616161;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 30px;
}
.col-sm-2.forSvg .ant-select.ant-select-outlined{
    width: 100%;
    margin-top: 10px;
    height: 45px;
}
.col-sm-2.forSvg .ant-select-selector {
    border-radius: 0 !important;
}
.mainPattern{
    stroke: #000;
    stroke-width: 3;
    fill-rule: nonzero;
}
.bodyPattern, .slelevePattern{
    fill-rule: nonzero;
}
.xyz{
    fill: #525f67;
}
.forStroek{
    fill: none;
    stroke: #1c1a00;
    stroke-miterlimit: 10;
}
.col-sm-10.buttonDispaly .row {
    margin: 0 !important;
}
.col-sm-2.forSvg {
    /* padding-left: 0; */
    background: #FFFFFF;
    box-shadow: 0px 4px 50px 0px #0000001A;
    border-radius: 0px 0px 15px 15px;
}
.imageeeSvg{
    margin-top: 40px;
    padding: 0 8px;
}
.col-sm-2.forSvg .shirtsvg{
    max-height: 500px;
    border: 1px solid #E1DDDD;
    box-shadow: 0px 4px 50px 0px #0000001A;
    margin-bottom: 10px;
    border-radius: 10px;
}
.row.noMargin{
    margin: 0 !important;
}
.ant-tabs-nav-wrap {
    border: 1px solid #fff;
}
.patternName{
    font-weight: 600;
    text-align: center;
    margin-top: 1rem;
    font-size: 20px;
    color: #000;
}
.cololrnMAe {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px !important;
    font-weight: 500;
    align-items: center;
    padding: 0 15px;
    width: 155px;
    text-align: center;
    padding-bottom: 30px;
    justify-content: center;
}
.row.boddyTab .col-sm-12 {
    box-shadow: 0px 4px 25px 0px #0000001A;
    margin-top: 20px !important;
    padding: 20px 20px 40px;
    border-radius: 15px;
    background: #FFFFFF;
    width: 95%;
    margin: auto;
    padding-top: 30px !important;
}
.row.boddyTab .col-sm-12 .ant-tabs-nav-list div {
    font-size: 17px;
}
.col-sm-2.forSvg {
    padding: 0 30px;
}
.row.boddyTab .col-sm-12 .ant-tabs-tab:hover{
    color: #000 !important;
}
.row.second.desktop {
    margin-bottom: 0 !IMPORTANT;
}
.ceeenter {
    margin-top: 3%;
}
.col-sm-10.buttonDispaly {
    max-width: 80%;
    width: 80%;
}
.col-sm-2.forSvg {
    max-width: 20% !important;
    width: 20%;
}
.ant-tabs-content-holder {
    padding: 20px 0;
}
.row.boddyTab .col-sm-12 .ant-tabs-tab{
    background: #EFEFEF;
    padding: 15px 40px;
    border-radius: 5px;
    font-weight: 500;
    margin-bottom: 10px;
}
.col-sm-10.buttonDispaly{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 12px;
}
.col-sm-10.buttonDispaly .container-fluid {
    padding: 0;
}
.col-sm-10.buttonDispaly button img{
    width: auto;
    height: 150px;
    margin-bottom: 10px;
}
.info{
    justify-content: flex-end;
    display: flex;
    gap: 30px;
    margin-bottom: 20px;
    width: 67%;
}
.col-sm-12.backBlue{
    background-color: #004890;
    padding: 40px 100px 0px 40px;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
}
.row.second.mobbile{
    display: none !important;
}
.col-sm-12.backBlue button{
    background: transparent;
    border: none;
    color: white;
    font-size: 14px;
    font-family: source-sans-pro, sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0px 30px;
    height: 50px;
    border-bottom: 8px solid #004890;
    border-radius: 0;
    margin-right: 20px;
}
.col-sm-12.backBlue button:hover{
    border-bottom: 8px solid #00A1FF;
    background-color: transparent !important;
    color: #fff !important;
}
.Button{
    border-bottom: 8px solid #00A1FF !important;
    background-color: transparent !important;
    color: #fff !important;
}
.ant-tabs-nav-list img{
    height: 150px;
}
.col-sm-12.center{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 65% !important;
    flex-direction: column;
    align-items: center;
}
.widthsss{
    width: 25% !important;
    height: 200px;
}
.col-sm-12.center .row.abcc{
    width: 500px !important;
}
.col-sm-12.noooPad{
    padding-left: 0;
}
.container-fluid.ddisplaay img{
    width: 100%;
    margin-bottom: 20px;
}
.ant-tabs-left >.ant-tabs-content-holder >.ant-tabs-content>.ant-tabs-tabpane, .ant-tabs-left >div>.ant-tabs-content-holder >.ant-tabs-content>.ant-tabs-tabpane {
    padding-left: 12px;
}
/*   */
@media screen and (max-width: 1600px) {
    .navbarss .row.second .col-sm-10 ul{
        padding-left: 0 !important;
    }
    .navbarss .row.second .col-sm-10 ul li {
        padding: 0 23px;
        cursor: pointer;
    }
    .row.home .col-sm-12.imageess img {
        height: 350px;
        width: 300px;
    }
    
    .row.footerOne {
        max-width: 90%;
    }
    .navbarss .row.second .col-sm-2 img {
        width: 75%;
    }
    .row.footerOne .col-sm-3 .logoFooter {
        width: 100%;
    }    
    .navbarss .row.second .col-sm-10 ul li{
        padding: 0 30px;
    }
    .navbarss .row .col-sm-3 a {
        padding: 10px 0px;
        width: 40% !important;
        text-align: center;
        font-size: 14px !important;
    }
    .col-sm-10.buttonDispaly button img {
        max-width: 120px;
        height: 135px;
    }
    .navbarss .row.second .col-sm-10 ul a,.navbarss .row.second .col-sm-10 ul p{
        font-size: 14px !important;
    }
    .cololrnMAe{
        font-size: 13px !important;
    }
    .fleexWrapColorOutsiude {
        width: 60px;
        height: 60px;
    }
    .fleexWrapColorInside {
        width: 50px;
        height: 50px;
     }
     .fleexWrapColor {
        display: flex;
        gap: 0px ! IMPORTANT;
        flex-wrap: wrap;
        padding-top: 15px;

    }
    .cololrnMAe {
        width: 140px;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-tab {
        padding: 13px 40px;
    }
    button.buynow, button.buynow:hover {
        font-size: 16px;
    }
    .Pricee .big {
        font-size: 22px;
    }
    .col-sm-2.forrm{
        width: 22%;
    }
    p.dropbtn svg {
        font-size: 13px;
    }
    .cololrnMAe {
        align-items: center;
        justify-content: flex-start;
    }
    .cololrnMAe p {
        font-size: 13px;
    }
}

@media screen and (max-width: 1440px) {
    .navbarss .row.second .col-sm-9{
        width: 75%;
    }
    .navbarss .row.second .col-sm-3{
        width: 25%;
    }
    .navbarss .row.second .col-sm-2 {
        padding: 0 20px 0 40px;
    }
    .row.thankyoouu h2 {
        font-size: 50px;
    }
    .row.thankyoouu p {
        font-size: 15px;
    }
    .navbarss .row.second .col-sm-10 ul li {
        padding: 0 25px;
    }
    .cololrnMAe {
        width: 120px !important; 
        align-items: center;
        justify-content: flex-start;
    }
    .swiper-wrapper .swiper-slide svg {
        height: 310px;
    }
    .cololrnMAe p {
        font-size: 13px;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-nav-list div {
        font-size: 14px;
    }

}

@media screen and (max-width: 1370px) {
    .navbarss .row.second .col-sm-9{
        width: 75%;
    }
    .navbarss .row.second .col-sm-3{
        width: 25%;
    }
    .navbarss .row.second .col-sm-2 {
        padding: 0 20px 0 40px;
    }
    .row.thankyoouu h2 {
        font-size: 50px;
    }
    .row.thankyoouu p {
        font-size: 15px;
    }
    .navbarss .row.second .col-sm-10 ul li {
        padding: 0 25px;
    }
    .cololrnMAe {
        width: 120px !important; 
    }
    .cololrnMAe {
        width: 110px !important;
        padding-right: 0;
        padding-left: 0;
    }
    .swiper-wrapper .swiper-slide svg {
        height: 310px;
    }
    .cololrnMAe p {
        font-size: 13px;
    }
    .row.home .col-sm-12.imageess img {
        height: 315px;
        width: 275px;
    }
    .ant-tabs-nav-operations {
        display: none !important;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-nav-list div {
        font-size: 14px;
    }

}

@media screen and (max-width: 1280px) {
    .navbarss .row .col-sm-3 a {
        padding: 9px 0px;
        font-size: 13px !important;
    }
    .ant-tabs-nav {
        width: 230px;
    }
    .navbarss .row.second .col-sm-10 ul li {
        padding: 0 15px;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-nav-list div {
        font-size: 13px;
    }
    .fleexWrapColorInside {
        width: 47px;
        height: 60px;
    }
    .fleexWrapColorOutsiude {
        width: 55px;
        height: 70px;
    }
    .row.boddyTab .col-sm-12 .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 0 0 16px;
        height: 48px;
    }
    .row.footerOne .col-sm-3 ul li a {
        font-size: 13px;
    }
    .col-sm-3.lisst {
        padding-left: 40px !important;
    }
    p.footerText {
        font-size: 13px !important;
    }
    p.headFooter, .col-sm-3.newsLetter p.headFooter  {
        font-size: 16px;
        margin-top: 30px;
    }
    .row.footerOne input {
        height: 40px;
    }
    .row.footerOne .col-sm-3 button, .row.footerOne .col-sm-3 button:hover {
        height: 43px;
    }
    .row.footerOne {
        max-width: 95%;
    }
    .row.row.footerOne .col-sm-3 {
        padding: 0 16px;
    }
    .checkout .row.personal .ant-col.ant-form-item-label label {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 12px !important;
    }

    .col-sm-8.forrmm {
        width: 75%;
    }
    .col-sm-10.buttonDispaly {
        width: 80%;
    }
    .col-sm-2.forSvg{
        width: 20%;
    }
    .cololrnMAe p {
        font-size: 11px;
    }
}

@media screen and (max-width: 1100px) {
    .navbarss .row.second .col-sm-10 ul li {
        padding: 0 15px;
    }
    .row.home h2 {
        padding: 30px 0;
        font-size: 34px;
    }    
    .container-fluid.checkout form {
        width: 90%;
    }
    .Pricee{
        padding: 8px 0;
    }
    .dropdown-content a {
        min-width: 220px;
    }
    .navbarss .row.second .col-sm-10 ul a, .navbarss .row.second .col-sm-10 ul p {
        font-size: 13px !important;
    }
    .col-sm-10.buttonDispaly button img{
        width: 100% !important;
        height: auto;
    }
    .row.ceenterr {
        margin: 50px 0;
    }
    .row.boddyTab  .ant-tabs-tab-btn {
        font-size: 13px;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-tab {
        padding: 12px 30px;
    }
    .col-sm-10.buttonDispaly {
        width: 78% !important;
    }
    .col-sm-2.forSvg{
        width: 22% !important;
    }
    .ant-tabs-left >.ant-tabs-nav .ant-tabs-tab, .ant-tabs-right >.ant-tabs-nav .ant-tabs-tab, .ant-tabs-left >div>.ant-tabs-nav .ant-tabs-tab, .ant-tabs-right >div>.ant-tabs-nav .ant-tabs-tab {
        padding: 0px 16px;
        text-align: center;
    }
    .cololrnMAe {
        padding: 0 4px;
        width: 90px;
    }
    .col-sm-10.buttonDispaly button{
        font-size: 13px;
        /* margin: 0px 0 0; */
        width: 110px;
        padding: 0 10px;
    
    
    
    }
    .socialImages img {
        width: 18%;
    }

    .col-sm-10.buttonDispaly .ceeenter button{
        width: auto !important;
        height: auto !important;
        padding: 10px 30px;
        display: flex;
        align-items: center;
    }
    .row.boddyTab .col-sm-12 {
        /* margin-top: 0px; */
        padding: 15px 15px 30px;
    }
    .row.boddyTab .ant-tabs .ant-tabs-tab+.ant-tabs-tab{
        margin: 0 0 0 16px;
    }
    .col-sm-2.forrm {
        width: 25%;
        padding-left: 0;
    }
    .col-sm-8.forrmm{
        width: 75%;
    }
    .navbarss .row.second .col-sm-2 img {
        width: 100%;
    }
    .fleexWrapColorInside {
        width: 40px;
        height: 40px;
    }
    .navbarss .row.second .col-sm-10 ul li {
        padding: 0 10px !important;
    }
    .col-sm-10.buttonDispaly button {
        width: 100% !important;
        height: 300px !important;
    }
    .row.boddyTab .ant-tabs-nav-list {
        gap: 0px;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-tab {
        background: #EFEFEF;
        padding: 15px 25px ! IMPORTANT;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-nav-list {
        width: 100%;
        text-align: center;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-nav-list div {
        font-size: 11px ;
    }
    .fleexWrapColorOutsiude {
        width: 50px;
        height: 50px;
    }
    .cololrnMAe {
        width: 95px !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .cololrnMAe p {
        font-size: 11px;
    }
    .row.home .col-sm-12.imageess img {
        height: 275px;
        width: 190px;
    }
    .col-sm-2.forSvg {
        padding: 0 10px;
    }
}

@media screen and (max-width: 850px){
    .row.second.mobbile{
        display: flex !important;
    }
    .socialImages img {
        width: 18%;
    }
    .row.boddyTab .col-sm-12 .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
        margin: 0;
        height: 38px !important;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-tab{
        margin-bottom: 0;
    }
    .col-sm-10.buttonDispaly{
        width: 75% !important;
    }
    .modal-body .container-fluid{
        padding-left: 0;
        padding-right: 0;
    }
    .fade.buyNowModal.modal.show .col-sm-2.forrm{
        width: 100% !important;
    }
    .fade.buyNowModal.modal.show .modal-dialog {
        max-width: 100%;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-tab {
        background: #EFEFEF;
        padding: 10px 10px ! IMPORTANT;
    }
    .fade.buyNowModal.modal.show .col-sm-8.forrmm {
        width: 100% !important;
    }
    .col-sm-10.buttonDispaly button {
        width: 100% !important;
        height: 175px !important;
    }
    .row.home .col-sm-12.imageess img {
        height: 275px;
        width: 45% !important;
    }
    .col-sm-12.noooPad {
        padding: 0;
    }
    .col-sm-12.imageess {
        padding-top: 30px;
    }
    /* .col-sm-2.forrm {
        width: 100%;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .container-fluid.ddisplaay {
        width: 50%;
    }
    
    .row.ceenterr {
        margin: 0 0 50px;
        flex-direction: column-reverse;
    }
    */
    .col-sm-8.forrmm {
        width: 65%;
    } 
    .col-sm-8.forrmm .col-sm-4, .col-sm-8.forrmm .col-sm-6{
        width: 100% ;
    }
    .col-sm-2.forrm{
        width: 35%;
    }
    .col-sm-2.forSvg {
        width: 25% !important;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-tab{
        padding: 10px 10px;
    }
    .Pricee .big {
        font-size: 18px;
    }
    button.buynow, button.buynow:hover {
        font-size: 14px;
    }
    .row.boddyTab .ant-tabs-tab-btn {
        font-size: 12px;
    }
    .cololrnMAe {
        padding: 0 5px;
        max-width: 67px;
        min-width: 77px;
    }
    .row.thankyoouu h2 {
        font-size: 40px;
    }
    .col-sm-10.buttonDispaly .row {
        margin: 0 !important;
        width: 100% !important;
    }
    .row.boddyTab .ant-tabs-nav-list {
        justify-content: space-around;
    }
    .row.thankyoouu p {
        font-size: 14px;
    }
    .row.boddyTab .ant-tabs >.ant-tabs-nav .ant-tabs-nav-list, .row.boddyTab .ant-tabs >div>.ant-tabs-nav .ant-tabs-nav-list {
        flex-wrap: wrap;
        gap: 0px !important;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-nav-list {
        width: 100%;
        text-align: center;
    }
    li.offCanvasBtns a{
        color: #000 !important;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-nav-list div {
        font-size: 11px;
    }
    li.offCanvasBtns{
        background: #fff !important;
        margin-bottom: 20px;
        text-align: center;
    }
    .fulll {
        gap: 20px;
    }    
    .row.payment.contact {
        width: 100%;
        margin-bottom: 130px;
    }
    li.offCanvasBtns:hover{
        background-color: #00A6A7 !important;
        border: 1px solid #292D76 !important;
        transition: all ease-in-out 400ms;
    }
    li.offCanvasBtns:hover a{
        color: #fff !important;
        transition: all ease-in-out 400ms;
    }
    .row.second.desktop{
        display: none !important;
    }
    .col-sm-12.offcanvaas{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
    }
    .col-sm-12.offcanvaas button{
        background: transparent !important;
        border: none !important;
        padding: 0;
    }
    .offcanvas.offcanvas-start.show, .offcanvas-header, .offcanvas-body{
        background: #292D76;
    }
    .accordion-header button{
        background: #292D76 !important;
        color: #fff !important;
        padding: 0;
    }
    .offcanvas-header {
        justify-content: flex-end;
    }
    .col-sm-12.offCanvasImage{
        display: flex;
        justify-content: center;
    }
    .col-sm-12.offCanvasList ul{
        padding-left: 0;
        list-style-type: none;
    }
    .col-sm-12.offCanvasList ul li a{
        color: #fff ;
        text-decoration: none;
    }
    .accordion-body {
        display: flex;
        flex-direction: column;
        background: #292D76;
        padding: 10px 0 0;
    }
    .accordion-item:first-of-type .accordion-button, .accordion-item:last-of-type .accordion-collapse, .accordion-body {
        border-radius: 0;
    }
    .accordion-button::after {
        background-image: url('./assets/arrowpng.png') !important;
    }

    .accordion-button:not(.collapsed) {
        color: #fff !important;
        box-shadow: none !important;
    }
    .accordion-body a{
        padding: 8px 0px;
    }
    .accordion-item{
        border: none;
    }
    .col-sm-12.offCanvasList {
        padding-top: 50px;
    }
    .col-sm-12.offCanvasList li {
        padding: 16px 20px;
    }

    .row.home .col-sm-12.imageess {
        flex-wrap: wrap;
        padding-bottom: 0;
    }
    .row.home .col-sm-12.imageess img {
        max-width: 50% !important;
        margin-bottom: 26px;
    }
    .row.home h2 {
        padding: 40px 0 0;
        font-size: 36px;
    }
    .row.home button {
        padding: 10px 70px;
        border-radius: 10px;
        font-size: 15px;
    }    
    .row.row.footerOne .col-sm-3 {
        width: 50%;
    }
    .col-sm-3.lisst {
        padding-left: 16px !important;
    }
    .row.footerOne {
        padding: 50px 20px 0;
    }
    .footerTwo .row .col-sm-12 ul li {
        padding: 0 10px;
    }
    .container-fluid.checkout {
        margin: 50px 0 30px;
    }
    .container-fluid.checkout form {
        width: 100%;
    }
    .row.boddyTab .ant-tabs >.ant-tabs-nav .ant-tabs-nav-list, .row.boddyTab .ant-tabs >div>.ant-tabs-nav .ant-tabs-nav-list {
        flex-wrap: wrap;
        gap: 10px;
    }
    .row.boddyTab .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 16px 0 0;
    }
    .col-sm-10.buttonDispaly button img {
        width: auto !important;
        height: 100px;
    }
    .row.boddyTab .ant-tabs .ant-tabs-ink-bar {
        display: none;
    }
    .navImage{
        width: 75%;
        margin: 10px 0;
    }
    .ant-tabs-nav {
        width: 175px;
    }
    .col-sm-10.buttonDispaly {
        width: 80% !important;
    }
    .row.boddyTab .col-sm-12 {
        width: 97%;
     }
     .fleexWrapColorInside {
        width: 30px;
        height: 33px;
    }
    .fleexWrapColorOutsiude {
        width: 40px;
        height: 45px;
    }
     .col-sm-2.forSvg {
        padding: 0 7px;
    }
    .button button {
        height: 40px;
    }
    .row.boddyTab .col-sm-12 {
        margin-left: 0 !IMPORTANT;
        margin-right: 0 !important;
        padding-right: 5px ! IMPORTANT;
        padding-left: 5px !important;
    }
    .col-sm-10.buttonDispaly button {
        font-size: 13px;
        /* margin: 0px 0 0; */
        width: 100%;
        padding: 0 10px;
        height: 175px;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-tab {
        margin: 0;
        width: auto !important;
    }
}

@media screen and (max-width: 575px){
    .row.row.footerOne .col-sm-3{
        width: 100%;
    }
    .row.home .col-sm-12.imageess img {
        height: auto !important;
        max-height: 200px;
    }
    .row.boddyTab .col-sm-12 .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
        padding: 8px !important;
        font-size: 2px !important;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-nav-list div {
        font-size: 9px;
    }
    .row.boddyTab .col-sm-12 .ant-tabs-tab {
        height: 38px;
    }
    .ant-tabs >.ant-tabs-nav .ant-tabs-nav-list, .ant-tabs >div>.ant-tabs-nav .ant-tabs-nav-list {
        padding: 0 !important;
    }
    .row.home h2 {
        padding: 20px 0 0;
        font-size: 28px;
    }
    .fleexWrapColorInside {
        width: 30px;
        height: 30px;
    }    
    .fleexWrapColorOutsiude {
        width: 40px;
        height: 40px;
    }
    .row.footerOne {
        padding: 50px 0px 0;
    }
    .col-sm-3.newsLetter{
        margin-bottom: 30px;
    }
    /* .row.home .col-sm-12.imageess img {
        max-width: 100% !important;
        max-height: 250px;
    } */
    .row.boddyTab .ant-tabs-tab-btn {
        font-size: 11px;
    }
    .col-sm-10.buttonDispaly button {
        font-size: 11px;
        gap: 10px;
        padding: 15px 0;
        max-width: 140px !important;
        width: 80px;
        height: auto !important;
    }
    .fleexWrapColor {
        justify-content: space-between;
    }
    .row.boddyTab .col-sm-12 {
        padding: 15px 4px 30px 4px;
        margin: 10px 0;
    }
    .restart{
        min-width: 130px !important;
        height: 40px !important;
        font-size: 13px !important;
        gap: 0px !important;
    }
    .ant-tabs-nav-list {
        width: 100% !important;
        justify-content: center;
    }
    .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
        margin: 0px 0 0 4px;
    }
    .col-sm-10.buttonDispaly, .col-sm-2.forSvg {
        width: 100% !important;
        padding: 0 !important;
    }

    .col-sm-2.forSvg, .col-sm-8.forrmm, .col-sm-2.forrm {
        width: 100% !important;
    }
    .col-sm-2.forrm{
        padding: 0 20px 30px;
        margin-top: 0;
    }
    .ant-tabs-nav {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .container-fluid.checkout {
        margin: 0;
    }   
    .row.boddyTab .col-sm-12 {
        width: 100% !important; 
    }
    .col-sm-12.previeeww {
        padding: 0 5px !important;
    }
    .row.footerOne .col-sm-3 .logoFooter {
        width: 75%;
    }
    .footerTwo .row .col-sm-12 {
        padding: 20px 35px;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
    }
    .footerTwo .row .col-sm-12 ul {
        padding-left: 0;
    }
    .cololrnMAe {
        padding: 0px 5px;
        width: 77px;
    }
    .col-sm-12.noooPad {
        padding: 0;
    }
    .col-sm-2.forSvg {
        padding: 0 20px;
        margin-top: 20px;
    }
    .col-sm-2.forSvg .shirtsvg {
        max-height: 100% !important;
        width: 100% !important;
    }
    button.buynow, button.buynow:hover{
        height: auto !important;
        padding: 10px 0;
    }
    /* .col-sm-10.buttonDispaly button img {
        height: auto;
        width: 70px !important;
    } */
    .col-sm-10.buttonDispaly button img {
        height: 90px;
        width: 70px !important;
        object-fit: contain;
    }
    .cololrnMAe p {
        font-size: 9px;
    }
    .col-sm-12.previeeww {
        padding: 0 5px;
    }
    .row.ceenterr{
        flex-direction: column-reverse;
    }
    .col-sm-10.buttonDispaly {
        max-width: 100% !important;
        width: 100% ! IMPORTANT;
    }
    .col-sm-2.forSvg {
        max-width: 97% !important;
        width: 100% !important;
        margin: 0px 2px;
    }
    .ant-tabs-nav {
        width: 100% !important;
    }
    .ant-tabs .ant-tabs-ink-bar {
        width: 60px ! IMPORTANT;
        height: 8px ! IMPORTANT;
        background: linear-gradient(180deg, #078BE6 0%, #19469D 100%) !important;
    }
    .ant-tabs-nav-list {
        gap: 0 !important;
    }
    .row.boddyTab button img {
        height: auto;
        width: 30% !important;
    }
}
